import HeaderResponse from '../interfaces/header-response.interfaces';
import fetch from 'node-fetch';

const initialHeaderConfig: HeaderResponse = {
  Logo: {
    Text: '',
    Url: '',
  },
  MainNavigation: [],
  MenuLabel: '',
  OneShopEnabled: false,
  Search: {
    Action: '',
    DealerSearchLink: '',
    Label: '',
    Legend: '',
    NewCarsLink: '',
    OneHeaderSearchClientId: '',
    Placeholder: '',
    QueryParam: '',
    ShowDealerSearchLink: false,
    ShowNewCarsLink: false,
    ShowUsedCarsLink: false,
    UsedCarsLink: '',
  },
};

const fetchHeaderConfig: (url: string) => Promise<HeaderResponse> = async (
  url,
) => {
  let headerConfig = initialHeaderConfig;
  if (url) {
    try {
      const response = await fetch(url);
      const responseData = await response.json();
      headerConfig = responseData;
    } catch (error) {
      console.warn(error);
    }
  }
  return headerConfig;
};

export { initialHeaderConfig, fetchHeaderConfig };
