/* eslint-disable max-lines */
import {
  AudiCopy1WithHoverStyle,
  generateMobileMenuMaxHeightCss,
  timeHeaderTransitionDelay,
  timeM,
  timeXL,
} from '../AudiHeader/AudiHeaderStyleUtils';
import { Theme, responsiveStyles } from '@audi/audi-ui-react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { HeaderStyledSubNavProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';

const headerSubNavStyles = (
  theme: Theme,
  isSubNavOpen: boolean,
): Array<{ maxNavItems: number; style: unknown }> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      bottom: { l: 'initial' },
      height: {
        l: `${isSubNavOpen ? 'auto' : '0'}`,
      },
      'max-height': {
        l: `${
          isSubNavOpen ? '660px' : '0'
        } /* 660px => teaser-image height * 2 - so it leaves space for about 8 cells of nav items in total */`,
      },
      opacity: { l: `${isSubNavOpen ? '1' : '0'}` },
      overflow: { l: 'hidden' },
      'overflow-y': { l: 'initial' },
      padding: {
        xl: '0 var(--one-header-side-spacing) 0 var(--one-header-flyout-left-spacing)',
      },
      top: { l: '72px' },
      transform: { l: 'none' },
      transition: {
        l: isSubNavOpen
          ? `max-height ${timeXL} ${theme.easing}, opacity ${timeM} ${theme.easing} ${timeHeaderTransitionDelay}`
          : `max-height ${theme.easing} ${timeXL}, opacity ${timeM} ${theme.easing}`,
      },
      'z-index': { l: '-1' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      bottom: { xl: 'initial' },
      height: {
        xl: `${isSubNavOpen ? 'auto' : '0'}`,
      },
      'max-height': {
        xl: `${
          isSubNavOpen ? '660px' : '0'
        } /* 660px => teaser-image height * 2 - so it leaves space for about 8 cells of nav items in total */`,
      },
      opacity: { xl: `${isSubNavOpen ? '1' : '0'}` },
      overflow: { xl: 'hidden' },
      'overflow-y': { xl: 'initial' },
      padding: {
        xl: '0 var(--one-header-side-spacing) 0 var(--one-header-flyout-left-spacing)',
      },
      top: { xl: '72px' },
      transform: { xl: 'none' },
      transition: {
        xl: isSubNavOpen
          ? `max-height ${timeXL} ${theme.easing}, opacity ${timeM} ${theme.easing} ${timeHeaderTransitionDelay}`
          : `max-height ${timeXL} ${theme.easing}, opacity ${timeM} ${theme.easing}`,
      },
      'z-index': { xl: '-1' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      bottom: { xxl: 'initial' },
      height: {
        xxl: `${isSubNavOpen ? 'auto' : '0'}`,
      },
      'max-height': {
        xxl: `${
          isSubNavOpen ? '660px' : '0'
        } /* 660px => teaser-image height * 2 - so it leaves space for about 8 cells of nav items in total */`,
      },
      opacity: { xxl: `${isSubNavOpen ? '1' : '0'}` },
      overflow: { xxl: 'hidden' },
      'overflow-y': { xxl: 'initial' },
      padding: {
        xxl: '0 var(--one-header-side-spacing) 0 var(--one-header-flyout-left-spacing)',
      },
      top: { xxl: '72px' },
      transform: { xxl: 'none' },
      transition: {
        xxl: isSubNavOpen
          ? `max-height ${timeXL} ${theme.easing}, opacity ${timeM} ${theme.easing} ${timeHeaderTransitionDelay}`
          : `max-height ${timeXL} ${theme.easing} ${timeHeaderTransitionDelay}, opacity ${timeM} ${theme.easing}`,
      },
      'z-index': { xxl: '-1' },
    },
  },
  {
    maxNavItems: -1,
    style: null,
  },
];

export const HeaderStyledSubNav = styled.div<HeaderStyledSubNavProps>`
  backface-visibility: hidden;
  background-color: var(--one-header-color-white);
  bottom: 0;
  display: block;
  height: ${({ isSubNavOpen }): string =>
    isSubNavOpen ? generateMobileMenuMaxHeightCss() : '0'};
  left: 0;
  ${({ headerNavigationItemsAmount, theme, isSubNavOpen }): string =>
    responsiveStyles(
      createBreakpointStyles(
        headerNavigationItemsAmount,
        headerSubNavStyles(theme, isSubNavOpen),
      ),
      theme.breakpoints,
    )}
  overflow-y: ${({ isSubNavOpen }): string =>
    isSubNavOpen ? 'auto' : 'hidden'};
  padding: 0 var(--one-header-side-spacing);
  position: absolute;
  right: 0;
  top: 0;
  transform: ${({ isSubNavOpen }): string =>
    isSubNavOpen ? 'translateX(0)' : 'translateX(100%) scaleY(1)'};
  /* stylelint-disable-next-line */
  transition: transform ${({ theme }): string => theme.easing} ${timeXL},
    height ${({ theme }): string => theme.easing} ${timeXL};
`;

const headerSubNavButtonStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: { l: 'none' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: { xl: 'none' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: { xxl: 'none' },
    },
  },
  {
    maxNavItems: -1,
    style: null,
  },
];

export const HeaderStyledSubNavButton = styled.button<{
  headerNavigationItemsAmount: number;
}>`
  align-items: center;
  background-color: var(--one-header-color-white);
  border: none;
  border-bottom: 1px solid #ccc;
  color: var(--one-header-color-black);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1em;
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(
        headerNavigationItemsAmount,
        headerSubNavButtonStyles(),
      ),
      theme.breakpoints,
    )}
  padding: var(--one-header-space-xl) 0 var(--one-header-space-m) 0;
  width: 100%;

  & svg {
    height: 24px;
    margin-right: var(--one-header-space-s);
    transform: scaleX(-1);
    width: 24px;
  }
`;

export const HeaderStyledSubNavTeaserWrapper = styled.div<{
  headerNavigationItemsAmount: number;
}>`
  background-color: var(--one-header-color-white);
  display: flex;
  margin: var(--one-header-space-xl) 0 0 0;
  ${({ theme }): string =>
    responsiveStyles(
      {
        'flex-direction': {
          m: 'row',
          xs: 'column',
        },
        'justify-content': {
          m: 'space-between',
        },
        margin: {
          xl: 'var(--one-header-space-xxl) 0 0 0;',
        },
      },
      theme.breakpoints,
    )}
  overflow: visible;
`;

export const HeaderStyledSubNavListContainer = styled.nav<{
  headerNavigationItemsAmount: number;
}>`
  margin: 0 0 var(--one-header-space-xxxl);
  ${({ theme }): string =>
    responsiveStyles(
      {
        'column-count': {
          xl: '2',
          xxl: '3',
        },
        'column-gap': {
          xl: 'var(--one-header-space-xxl)',
        },
        'margin-right': {
          m: 'var(--one-header-space-xl)',
        },
      },
      theme.breakpoints,
    )}
`;

export const HeaderStyledSubNavList = styled.ul<{
  headerNavigationItemsAmount: number;
}>`
  list-style: none;
  margin: 0;
  ${({ theme }): string =>
    responsiveStyles(
      {
        width: {
          xl: '320px',
          xs: '100%',
        },
      },
      theme.breakpoints,
    )}
  opacity: 1;
  padding: 0;
`;
