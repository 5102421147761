import * as React from 'react';

import { HeaderStyledNav, HeaderStyledNavWrapper } from './AudiHeaderNavStyles';
import { useEffect, useState } from 'react';
import AudiHeaderActiveBar from '../AudiHeaderActiveBar/AudiHeaderActiveBar';
import AudiHeaderNavItem from '../AudiHeaderNavItem/AudiHeaderNavItem';
import { AudiHeaderNavProps } from '../../interfaces/header-components.interfaces';

const AudiHeaderNav: React.FC<AudiHeaderNavProps> = ({
  audiMarketContextService,
  headerNavigationItemsAmount,
  navigation,
  headerDispatch,
  headerState,
  headerStateService,
  navigationElement,
  isClosing,
  isOpen,
  useFootnoteReferenceServiceTextParserHook,
  windowWidth,
  layerManager,
}) => {
  const [useOneLayer, setUseOneLayer] = useState(false);

  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof audiMarketContextService !== 'undefined') {
        try {
          await audiMarketContextService.initMarketContext();
          setUseOneLayer(
            audiMarketContextService.hasEnvScope('ONEHEADER_ONE_LAYER'),
          );
        } catch (error) {
          console.warn(error);
        }
      }
    })();
  }, [audiMarketContextService]);

  return (
    <HeaderStyledNavWrapper
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isOpen={isOpen}
      isSubNavOpen={headerState.activeItem.index !== -1}
      ref={navigationElement}
      showSearch={headerState.activeItem.showSearch}
    >
      <HeaderStyledNav
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        isOpen={isOpen}
        isSubNavOpen={headerState.activeItem.index !== -1}
      >
        {navigation.map((navigationItem, index) => {
          const link = navigationItem.Link;
          const key = `${link.Text}${link.Url}`;

          return (
            <AudiHeaderNavItem
              audiMarketContextService={audiMarketContextService}
              headerDispatch={headerDispatch}
              headerNavigationItemsAmount={headerNavigationItemsAmount}
              headerState={headerState}
              headerStateService={headerStateService}
              index={index}
              isClosing={isClosing}
              isSubNavOpen={headerState.activeItem.index !== -1}
              item={navigationItem}
              key={key}
              layerManager={layerManager}
              useFootnoteReferenceServiceTextParserHook={
                useFootnoteReferenceServiceTextParserHook
              }
              useOneLayer={useOneLayer}
            />
          );
        })}
      </HeaderStyledNav>
      <AudiHeaderActiveBar
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        headerState={headerState}
        windowWidth={windowWidth}
      />
    </HeaderStyledNavWrapper>
  );
};

export default AudiHeaderNav;
