import * as React from 'react';
import { ErrorInfo, ReactNode } from 'react';
import { AppLoaderErrorBoundaryState } from '../../interfaces/header-components.interfaces';
type AppLoaderErrorBoundaryProps = Record<string, unknown>;

class AppLoaderErrorBoundary extends React.Component<
  AppLoaderErrorBoundaryProps,
  AppLoaderErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error): AppLoaderErrorBoundaryState {
    return { error };
  }

  constructor(props: AppLoaderErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO log the error to an error reporting service
    console.error('[audi-feature-app-header] ', error, errorInfo);
  }

  render(): ReactNode {
    const { error } = this.state;

    const { children } = this.props;

    if (error !== null) {
      // No error message is displayed when a feature app crashes. It is just not rendered.
      return null;
    }

    return children;
  }
}

export default AppLoaderErrorBoundary;
