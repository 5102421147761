import * as React from 'react';
import { Theme, responsiveStyles } from '@audi/audi-ui-react';
import { AudiHeaderActiveBarProps } from '../../interfaces/header-components.interfaces';
import { HeaderStyledActiveBarProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';
import styled from 'styled-components';
import { timeL } from '../AudiHeader/AudiHeaderStyleUtils';
import { useMemo } from 'react';

const headerStyledActiveBarStyles = (
  theme: Theme,
): Array<{ maxNavItems: number; style: unknown }> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      'background-color': {
        l: 'var(--one-header-color-black)',
      },
      display: { l: 'block' },
      height: { l: '2px' },
      left: { l: '0' },
      opacity: { l: '0' },
      position: { l: 'absolute' },
      top: {
        l: `/* corresponds to the top position of a navigation being 72px high */
          calc(2 * var(--one-header-space-l))`,
      },
      transition: {
        l: `left ${timeL} ${theme.easing},
          width ${timeL} ${theme.easing},
          opacity ${timeL}`,
      },
      width: { l: '0' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      'background-color': {
        xl: 'var(--one-header-color-black)',
      },
      display: { xl: 'block' },
      height: { xl: '2px' },
      left: { xl: '0' },
      opacity: { xl: '0' },
      position: { xl: 'absolute' },
      top: {
        xl: `/* corresponds to the top position of a navigation being 72px high */
          calc(2 * var(--one-header-space-l))`,
      },
      transition: {
        xl: `left ${timeL} ${theme.easing},
        width ${timeL} ${theme.easing},
        opacity ${timeL}`,
      },
      width: { xl: '0' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      'background-color': {
        xxl: 'var(--one-header-color-black)',
      },
      display: { xxl: 'block' },
      height: { xxl: '2px' },
      left: { xxl: '0' },
      opacity: { xxl: '0' },
      position: { xxl: 'absolute' },
      top: {
        xxl: `/* corresponds to the top position of a navigation being 72px high */
          calc(2 * var(--one-header-space-l))`,
      },
      transition: {
        xxl: `left ${timeL} ${theme.easing},
          width ${timeL} ${theme.easing},
          opacity ${timeL}`,
      },
      width: { xxl: '0' },
    },
  },
];

const HeaderStyledActiveBar = styled.div<HeaderStyledActiveBarProps>`
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(
        headerNavigationItemsAmount,
        headerStyledActiveBarStyles(theme),
      ),
      theme.breakpoints,
    )};
`;

const AudiHeaderActiveBar: React.FC<AudiHeaderActiveBarProps> = ({
  headerNavigationItemsAmount,
  headerState,
  windowWidth,
}) => {
  const activeBarStyles = useMemo(() => {
    const currentActiveAnchor = headerState.activeItem.anchor;
    let cssProperties = {
      left: 0,
      opacity: 0,
      width: 0,
    };
    if (currentActiveAnchor) {
      cssProperties = {
        left: currentActiveAnchor.offsetLeft,
        opacity: 1,
        width: currentActiveAnchor.clientWidth,
      };
    }

    return cssProperties;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerState.activeItem.anchor, windowWidth]);

  return (
    <HeaderStyledActiveBar
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      style={activeBarStyles}
    />
  );
};

export default AudiHeaderActiveBar;
