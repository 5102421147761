import * as React from 'react';
import { Text } from '@audi/audi-ui-react';

const AudiHeaderTeaserTechLabel: React.FC<{
  techLabelValue: string;
  useFootnoteReferenceServiceTextParserHook: (htmlString: string) => string;
}> = ({ useFootnoteReferenceServiceTextParserHook, techLabelValue }) => {
  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    techLabelValue.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      'illegalscript',
    ),
  );
  return (
    <Text as="p" variant="copy2">
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: dangerousHTML,
        }}
      />
    </Text>
  );
};

export default AudiHeaderTeaserTechLabel;
