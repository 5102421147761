import * as React from 'react';
import { timeL, timeS } from '../AudiHeader/AudiHeaderStyleUtils';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiHeaderSearchProps } from '../../interfaces/header-components.interfaces';
import { FeatureAppLoader } from '@feature-hub/react';
import { HeaderSearchStyledProps } from '../../interfaces/header-styled-components.interfaces';
import { Loader } from '@audi/audi-ui-react';
import { createResetActiveItemAction } from '../../actions/header-actions';
import styled from 'styled-components';

const HeaderSearchControls = styled.div<HeaderSearchStyledProps>`
  position: absolute;
  top: 0;
  width: 100%;

  & > legend {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  & > button {
    background-color: transparent;
    border: none;
    color: var(--one-header-color-black);
    cursor: pointer;
    display: flex;
    left: var(--one-header-side-spacing);
    opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
    padding: 0;
    position: absolute;
    top: var(--one-header-space-l);
    transition: opacity ${timeS};

    & > svg {
      height: 24px;
      transform: scaleX(-1);
      width: 24px;
    }
  }
`;

const HeaderSearchFeatureApp = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  margin: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: relative;
  transition: opacity ${timeS};
  width: 100%;
`;

const HeaderSearch = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  display: block;
  height: 71px;
  left: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${({ theme }): string => theme.easing} ${timeL};
  visibility: ${({ isSearchOpen }): string =>
    isSearchOpen ? 'visible' : 'hidden'};
  width: 100%;
  z-index: 5;
`;

const LoaderContainer = styled.div`
  margin: 10px auto;
  text-align: center;
`;

const AudiHeaderSearch: React.FC<AudiHeaderSearchProps> = (props) => {
  const {
    isSearchOpen,
    search,
    headerDispatch,
    searchInputFeatureAppUrl,
    searchResultsFeatureAppUrl,
    headerStateService,
    requestLoadSearchApp,
  } = props;

  const searchInputFeatureAppRef = React.useRef(null);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void = (
    event,
  ) => {
    event.preventDefault();

    headerDispatch(createResetActiveItemAction(headerStateService));
  };

  // if the feature app delays in loading we trigger displaying the search input field once again
  React.useEffect(() => {
    if (
      isSearchOpen &&
      searchInputFeatureAppRef &&
      searchInputFeatureAppRef.current &&
      searchInputFeatureAppRef.current.hasChildNodes()
    ) {
      const childNodes = searchInputFeatureAppRef.current.childNodes[0];
      if (childNodes && !childNodes.hasChildNodes()) {
        let timesRun = 0;

        const intervalCheckFeatureAppLoaded = setInterval(() => {
          const childNodesUpdated =
            searchInputFeatureAppRef.current.childNodes[0];
          if (childNodesUpdated && childNodesUpdated.hasChildNodes()) {
            // TODO: the following line is to support old integrator. Remove this line after the new integrator with feature-services > 3.2.2 are live
            headerStateService.setShowSearch(false);
            headerStateService.setShowSearch(true, true);
            clearInterval(intervalCheckFeatureAppLoaded);
            setShowLoader(false);
            return;
          }

          if (timesRun >= 2) {
            setShowLoader(true);
          }

          if (++timesRun > 15) {
            clearInterval(intervalCheckFeatureAppLoaded);
          }
        }, 500);
      }
    }
  }, [isSearchOpen, headerStateService]);

  const searchContentJsx = requestLoadSearchApp ? (
    <React.Fragment>
      <div>
        <AppLoaderErrorBoundary>
          <FeatureAppLoader
            featureAppId="header-search-input"
            src={searchInputFeatureAppUrl}
          ></FeatureAppLoader>
        </AppLoaderErrorBoundary>
      </div>
      <div>
        <AppLoaderErrorBoundary>
          <FeatureAppLoader
            featureAppId="header-search-results"
            src={searchResultsFeatureAppUrl}
          ></FeatureAppLoader>
        </AppLoaderErrorBoundary>
      </div>
    </React.Fragment>
  ) : null;

  return (
    <React.Fragment>
      <HeaderSearch isSearchOpen={isSearchOpen}>
        {showLoader && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <HeaderSearchFeatureApp
          isSearchOpen={isSearchOpen}
          ref={searchInputFeatureAppRef}
        >
          {searchContentJsx}
        </HeaderSearchFeatureApp>
        <HeaderSearchControls isSearchOpen={isSearchOpen}>
          <legend>{search.Legend}</legend>
          <button onClick={handleClick} type="button">
            <svg
              height="48"
              viewBox="0 0 24 24"
              width="48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16.9L15.5 11.5 10 6.1"
                fill="none"
                stroke="currentColor"
              />
            </svg>
          </button>
        </HeaderSearchControls>
      </HeaderSearch>
    </React.Fragment>
  );
};
export default AudiHeaderSearch;
