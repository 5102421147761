import * as React from 'react';
import { AudiHeaderMiniCartProps } from '../../interfaces/header-components.interfaces';
import { FeatureAppLoader } from '@feature-hub/react';
import { HeaderStyledMiniCartProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';
import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';

const headerStyledMiniCartStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        l: 'flex',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        xl: 'flex',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        xxl: 'flex',
      },
    },
  },
];

const HeaderStyledMiniCart = styled.div<HeaderStyledMiniCartProps>`
  display: ${({ isOpen }): string => (isOpen ? 'none' : 'flex')};
  margin: 0 ${({ theme }): string => theme.spacing.l.xs}px 0 0;
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(
        headerNavigationItemsAmount,
        headerStyledMiniCartStyles(),
      ),
      theme.breakpoints,
    )}
`;

const AudiHeaderMiniCart: React.FC<AudiHeaderMiniCartProps> = (props) => {
  const {
    headerNavigationItemsAmount,
    isOpen,
    miniCartFeatureAppBaseUrl,
    miniCartFeatureAppSrc,
    oneShopUbffUrl,
  } = props;

  const configObject = {
    ubffEndpoint: oneShopUbffUrl,
  };

  return (
    <HeaderStyledMiniCart
      data-audi-core-tracking-exclude="true"
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isOpen={isOpen}
    >
      <FeatureAppLoader
        baseUrl={miniCartFeatureAppBaseUrl}
        config={configObject}
        featureAppId="header-mini-cart"
        src={miniCartFeatureAppSrc}
      />
    </HeaderStyledMiniCart>
  );
};

export default AudiHeaderMiniCart;
