import HeaderResponse from '../interfaces/header-response.interfaces';
import { PartnerId } from '../types/partner-id';

export function replacePartnerId(
  componentData: HeaderResponse,
  partnerId?: PartnerId,
): HeaderResponse {
  let replacementComponentData = JSON.stringify(componentData);
  replacementComponentData = replacementComponentData
    .replace(/\{kvpsid\}|%7Bkvpsid%7D/gi, partnerId?.kvpsid || '')
    .replace(/\{kvpsSyncId\}|%7BkvpsSyncId%7D/gi, partnerId?.kvpsSyncId || '');

  return JSON.parse(replacementComponentData);
}
