import * as React from 'react';
import { getClassNamesForLinks, getLinkTarget } from '../../services';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { AudiCopy1WithHoverStyle } from '../AudiHeader/AudiHeaderStyleUtils';
import { AudiHeaderSubNavListItemProps } from '../../interfaces/header-components.interfaces';
import { Theme } from '@audi/audi-ui-react';
import { useCallback } from 'react';

/* ; am Ende oder nicht?? */
const HeaderStyledSubNavListItem = styled.li<{
  isAudiSport: boolean;
  theme: Theme;
}>`
  margin-bottom: var(--one-header-space-l);

  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ isAudiSport, theme }): string =>
    isAudiSport &&
    `
    padding-left: 42px;
    position: relative;

    &::before {
      background-color: ${theme.colors.ui.accent};
      content: "";
      display: inline-block;
      height: 24px;
      left: 6px;
      position: absolute;
      transform: skew(-28deg);
      vertical-align: top;
      width: 24px;
    }
  `}
`;

const HeaderStyledSubNavListItemLink = styled.a`
  ${({ theme }): FlattenSimpleInterpolation => AudiCopy1WithHoverStyle(theme)}

  ${({ theme }): string => `
    color: #666;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.utility.black}
    }
  `}
`;

const AudiHeaderSubNavListItem: React.FC<AudiHeaderSubNavListItemProps> = ({
  listItem,
  useFootnoteReferenceServiceTextParserHook,
  onItemClick,
  useOneLayer,
}) => {
  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    listItem.Text.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      'illegalscript',
    ),
  );

  const onClick = useCallback(
    (event) => {
      return onItemClick(event, listItem);
    },
    [listItem, onItemClick],
  );

  return (
    <HeaderStyledSubNavListItem isAudiSport={listItem.IsAudiSport}>
      <HeaderStyledSubNavListItemLink
        className={getClassNamesForLinks(listItem, useOneLayer)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: dangerousHTML,
        }}
        href={listItem.Url}
        onClick={onClick}
        target={getLinkTarget(listItem)}
      />
    </HeaderStyledSubNavListItem>
  );
};

export default AudiHeaderSubNavListItem;
