import React, { useEffect, useState } from 'react';

import AudiHeader from './AudiHeader';
import { AudiHeaderDataManagerProps } from '../../interfaces/header-components.interfaces';
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service/dist/store/v2/footnote-reference-store';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { INavigationSearchResponse } from '@volkswagen-onehub/audi-search-manager-service/dist/interfaces';
import { PartnerId } from '../../types/partner-id';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';
import { replacePartnerId } from '../../utils/replace-partner';

const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  ssrHeaderConfig,
  headerConfigDataUrl,
  loginFeatureAppUrl,
  miniCartFeatureAppBaseUrl,
  miniCartFeatureAppSrc,
  oneShopUbffUrl,
  searchInputFeatureAppUrl,
  searchResultsFeatureAppUrl,
  headerStateService,
  featureAppID,
  referenceServiceManager,
  searchManagerService,
  layerManager,
  enablePartnerIdReplacement,
}) => {
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined);
  const wrapperElement = React.createRef<HTMLDivElement>();
  const [headerConfig, setHeaderConfig] =
    useState<HeaderResponse>(ssrHeaderConfig);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const headerConfigWithPartnerId = replacePartnerId(headerConfig, partnerId);

  const useFootnoteReferenceServiceTextParserHook =
    createUseFootnoteReferenceServiceTextParserHook({
      footnotes,
      referenceServiceManager,
    });

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback(
        (_footnotes: EnumerableFootnote[]) => {
          setFootnotes(_footnotes);
        },
      );
    }
    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // always load clientside again, to be sure, not to work with a cached version from SSR
    fetchHeaderConfig(headerConfigDataUrl).then((fetchedHeaderConfigData) => {
      setHeaderConfig(fetchedHeaderConfigData);
      setIsLoading(false);
    });
  }, [headerConfigDataUrl, ssrHeaderConfig]);

  useEffect(() => {
    if (searchManagerService && window) {
      const {
        setVTPAndDealerSearchConfig,
        setDealerSearchRedirectUrl,
        setClientIdParam,
        setNavigationSearchData,
      } = searchManagerService;

      if (
        headerConfigWithPartnerId?.Search?.OneHeaderSearchClientId &&
        setClientIdParam
      ) {
        setClientIdParam(
          headerConfigWithPartnerId.Search.OneHeaderSearchClientId,
        );
      }
      if (
        headerConfigWithPartnerId?.MainNavigation &&
        setNavigationSearchData
      ) {
        setNavigationSearchData({
          MainNavigation: headerConfigWithPartnerId.MainNavigation,
        } as INavigationSearchResponse);
      }

      const config = {
        dealerSearch: !!headerConfigWithPartnerId?.Search?.ShowDealerSearchLink,
        newStockCarSearch: !!headerConfigWithPartnerId?.Search?.ShowNewCarsLink,
        usedAndPreOwnedStockCarSearch:
          !!headerConfigWithPartnerId?.Search?.ShowUsedCarsLink,
      };

      setVTPAndDealerSearchConfig(config);

      if (
        headerConfigWithPartnerId?.Search?.DealerSearchLink &&
        setDealerSearchRedirectUrl
      ) {
        setDealerSearchRedirectUrl(
          headerConfigWithPartnerId.Search.DealerSearchLink,
        );
      }
    }
  }, [headerConfigWithPartnerId, searchManagerService]);

  useEffect(() => {
    if (
      typeof window == 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: PartnerId): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore(
      'dbadDealerStore',
      partnerCallback,
    );

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore(
        'dbadDealerStore',
        partnerCallback,
      );
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  return (
    <AudiHeader
      audiMarketContextService={audiMarketContextService}
      featureAppID={featureAppID}
      headerConfig={headerConfigWithPartnerId}
      headerStateService={headerStateService}
      isLoading={isLoading}
      layerManager={layerManager}
      loginFeatureAppUrl={loginFeatureAppUrl}
      miniCartFeatureAppBaseUrl={miniCartFeatureAppBaseUrl}
      miniCartFeatureAppSrc={miniCartFeatureAppSrc}
      oneShopUbffUrl={oneShopUbffUrl}
      searchInputFeatureAppUrl={searchInputFeatureAppUrl}
      searchResultsFeatureAppUrl={searchResultsFeatureAppUrl}
      useFootnoteReferenceServiceTextParserHook={
        useFootnoteReferenceServiceTextParserHook
      }
      wrapperElement={wrapperElement}
    />
  );
};

export default AudiHeaderDataManager;
